









































































































































































































































































































































import mixins from "vue-typed-mixins";
import MyMixin from "@/plugins/myMixin";
import {types} from "@/services/westore/enums";

export default mixins(MyMixin).extend({
  name: "zLab",
   methods:{
    showHideContacts() {
      if (this.globals.whatsappPopupVisible) {
        this.logGAEvent("Public", "Open WhatsApp Widget");
      }
      this.globals.whatsappPopupVisible = !this.globals.whatsappPopupVisible;
    }
  },
  data: () => ({
    storageDelivery: [
      {
        pretext:
          "Tired of living with boxes?<br>Don’t want to be tied down by expensive long-term warehouse contracts?",
        title: ["Ultra-flexible, ", "on-demand ", "warehousing"],
        text: "We provide operations and logistics for small, medium and ecommerce businesses. Store a little or a lot – and only pay for the space and time you need.",
        imageUrl: require("@/assets/Ultra-Flexible-1536x958.jpg.webp"),
      },
      {
        pretext:
          "Frustrated with driving back and forth, cramming your car full of boxes, or paying expensive couriers?",
        title: ["Hassle-free, ", "same-day ", "delivery "],
        text: "We offer simple pricing, same day delivery and end to end fulfillment solutions.",
        imageUrl: require("@/assets/Hassle-free-2-1536x970.jpg.webp"),
      },
      {
        pretext: "Wasting time and money on managing cancellations or returns?",
        title: ["Fast, ", "cost-effective ", "returns"],
        text: "Through our extensive network, we’ve got it sorted. No hassle. No financial headache.",
        imageUrl: require("@/assets/Fast-cost-effective.jpg"),
      },
    ],
    warehouseFeatures: [
      "Network of warehouses",
      "No IT integration required",
      "Case Picking",
      "Pick & Pack/Kitting",
      "Cross-Docking",
      "Shipment Consolidation",
      "Transloading",
      "Fulfilment",
      "Non-palletized & palletized storage",
      "Palletization & Pallet Rebuild",
      "Forward Stocking Locations",
      "Labeling / barcoding",
      "Custom Services",
      "B2B and B2C Distribution",
      "Order Management",
    ],
  }),
  computed: {
    types() {
      return types;
    }
  }
});
